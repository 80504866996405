import { config } from '@endaoment-frontend/config';

export const home = () => `${config.baseUrls.docs}/`;

export const governance = () => `${config.baseUrls.docs}/governance`;
export const termsAndConditions = () => `${config.baseUrls.docs}/governance/documentation/terms-and-conditions`;
export const privacyPolicy = () => `${config.baseUrls.docs}/governance/documentation/personal-data-usage-and-storage`;
export const noDonorBenefit = () =>
  `${config.baseUrls.docs}/governance/documentation/fund-agreement#no-donor-benefit-clause`;
export const orgFundingPolicy = () =>
  `${config.baseUrls.docs}/governance/documentation/mission-values#organization-funding-policy`;
export const fundAgreement = () => `${config.baseUrls.docs}/governance/documentation/fund-agreement`;
export const otcDisclaimer = () =>
  `${config.baseUrls.docs}/governance/documentation/terms-and-conditions#11-a-over-the-counter-donation-disclaimer`;
export const investmentPolicy = () => `${config.baseUrls.docs}/governance/documentation/investment-policy`;
export const protectionOfAssets = () => `${config.baseUrls.docs}/governance/resources/protection-of-assets`;
export const calculatorDisclaimer = () => `${config.baseUrls.docs}/governance/resources/calc-disclaimer`;

export const donors = () => `${config.baseUrls.docs}/donors`;
export const aboutDafs = (params?: { hash?: 'donor-advised-fund-daf-types' }) => {
  const base = `${config.baseUrls.docs}/donors/about/donor-advised-funds`;
  if (!params) return base;
  return `${base}#${params.hash}`;
};
export const aboutDafMigrations = () => `${config.baseUrls.docs}/donors/about/about-daf-migrations`;
export const aboutPortfolios = () => `${config.baseUrls.docs}/donors/about/about-investment-portfolios`;
export const openingADaf = () => `${config.baseUrls.docs}/donors/getting-started/opening-a-fund`;
export const onboardingAnOrg = () =>
  `${config.baseUrls.docs}/donors/getting-started/recommending-grants#onboarding-an-organization`;
export const feeSchedule = (params?: { hash?: 'tiered-inbound-fee' }) => {
  const base = `${config.baseUrls.docs}/donors/resources/fee-schedule`;
  if (!params) return base;
  return `${base}#${params.hash}`;
};
export const donorFaq = (params?: {
  hash?: 'is-endaoment-a-legitimate-nonprofit' | 'what-is-endaoment' | 'why-donate-through-endaoment';
}) => {
  const base = `${config.baseUrls.docs}/donors/resources/donor-faq`;
  if (!params) return base;
  return `${base}#${params.hash}`;
};

export const organizations = () => `${config.baseUrls.docs}/organizations`;
export const claimYourOrg = () => `${config.baseUrls.docs}/organizations/grants-and-claims/claim-your-organization`;
